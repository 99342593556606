import "./CampaignManagement.css";
import { useEffect, useState, useContext, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import LineGraph from "../components/LineGraph";
import SentimentChart from "../components/SentimentChart";
import FollowUnfollowChart from "../components/FollowUnfollowChart";
import DateRangePicker from "../components/DateRangePicker";
import AgeGroupChart from "../components/AgeGroupChart";
import GenderChart from "../components/GenderChart";
import { baseUrl } from "../shared";
import FileManager from "../components/FileManager";
import PayPalButton from "../components/PayPalButton";
import { LoginContext } from "../App";
import { BreadCrumb } from 'primereact/breadcrumb';
import 'primeicons/primeicons.css'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { FilterMatchMode } from "primereact/api";


export default function CampaignManagement() {
    const [page, setPage] = useState("campaigns");
    // const [page, setPage] = useState("services");
    const [servicePage, setServicePage] = useState("ugc-service");
    const [engagementPage, setEngagementPage] = useState("post-metrics");
    const [demographicsPage, setDemographicsPage] = useState("country");
    const { instagram_id } = useParams();
    const [fileDialogOpen, setFileDialogOpen] = useState(false);

    const [campaigns, setCampaigns] = useState([]);
    const [selectedContractID, setSelectedContractID] = useState("");
    const [selectedVersionID, setSelectedVersionID] = useState("");
    const [selectedCampaignFile, setSelectedCampaignFile] = useState("");


    const [accountMetrics, setAccountMetrics] = useState({
        impressions: {
            label: "",
            labels: [],
            data: [],
        },
        reach: {
            label: "",
            labels: [],
            data: [],
        },
        likes: {
            label: "",
            labels: [],
            data: [],
        },
        views: {
            label: "",
            labels: [],
            data: [],
        },
        shares: {
            label: "",
            labels: [],
            data: [],
        },
        comments: {
            label: "",
            labels: [],
            data: [],
        },
        website_clicks: {
            label: "",
            labels: [],
            data: [],
        },
        sentiment_analysis: {
            label: "",
            labels: [],
            data: [],
        },
        follow_unfollow: {
            label: "",
            labels: [],
            data: [],
        },
    });

    const [postMetrics, setPostMetrics] = useState({
        impressions: {
            label: "",
            labels: [],
            data: [],
        },
        reach: {
            label: "",
            labels: [],
            data: [],
        },
        like_count: {
            label: "",
            labels: [],
            data: [],
        },
        views: {
            label: "",
            labels: [],
            data: [],
        },
        shares: {
            label: "",
            labels: [],
            data: [],
        },
        comments_count: {
            label: "",
            labels: [],
            data: [],
        },
        saved: {
            label: "",
            labels: [],
            data: [],
        },
        video_views: {
            label: "",
            labels: [],
            data: [],
        },
    });

    const [reelMetrics, setReelMetrics] = useState({
        ig_reels_avg_watch_time: {
            label: "",
            labels: [],
            data: [],
        },
        plays: {
            label: "",
            labels: [],
            data: [],
        },
        ig_reels_video_view_total_time: {
            label: "",
            labels: [],
            data: [],
        },
    });

    const [ageDemographics, setAgeDemographics] = useState({
        follower_demographics: {},
        engaged_audience_demographics: {},
        reached_audience_demographics: {},
    });
    const [genderDemographics, setGenderDemographics] = useState({
        follower_demographics: {},
        engaged_audience_demographics: {},
        reached_audience_demographics: {},
    });
    const [cityDemographics, setCityDemographics] = useState({
        follower_demographics: [],
        engaged_audience_demographics: [],
        reached_audience_demographics: [],
    });
    const [countryDemographics, setCountryDemographics] = useState({
        follower_demographics: [],
        engaged_audience_demographics: [],
        reached_audience_demographics: [],
    });


    const navigate = useNavigate();
    const [loggedIn, setLoggedIn] = useContext(LoginContext);
    useEffect(() => {
        if (!loggedIn) {
            navigate("/login");
        }
    }, []);

    const items = [
        {label: 'Campaigns', 'url': '/campaigns'}
        // { label: 'Home', url: '/' },
        // { label: 'Home', url: '/' },
        // { label: 'Home', url: '/' },
        // { label: 'Home', url: '/' },
    ];

    // const home = { label: 'Campaigns', url: '/campaigns'};
    const home = { icon: 'pi pi-home', url: '' };


    // const campaigns_table = [
    //     {id: 1, campaign_name: "Fitness Campaign", num_influencers: 100, active_influencers: 70, views: '100k', likes: '10k', shares: '1k', date:"12-3-2024"},
    //     {id: 1, campaign_name: "Fitness Campaign", num_influencers: 100, active_influencers: 70, views: '100k', likes: '10k', shares: '1k', date:"12-3-2024"},
    //     {id: 1, campaign_name: "Fitness Campaign", num_influencers: 100, active_influencers: 70, views: '100k', likes: '10k', shares: '1k', date:"12-3-2024"}
    // ];

    const [campaignsTable, setCampaignsTable] = useState([]);
    const [filters, setFilters] = useState({

    });

    const [loading, setLoading] = useState(false);

    const newCampaignOverlayRef = useRef(null);
    const [newCampaignValue, setNewCampaignValue] = useState("");

    useEffect(() => {
        const url =
            baseUrl + `api/instagram/data/media?instagram_id=${instagram_id}`;
        fetch(url, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + localStorage.getItem("access"),
            },
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error("Error in fetching data");
                }
                return response.json();
            })
            .then((data) => {
                data.instagram_media_data.forEach((item) => {
                    Object.keys(postMetrics).forEach((key) => {
                        postMetrics[key].label = key;
                        postMetrics[key].labels.push(item.media_id);
                        postMetrics[key].data.push(item[`${key}`]);
                    });
                    Object.keys(reelMetrics).forEach((key) => {
                        reelMetrics[key].label = key;
                        reelMetrics[key].labels.push(item.media_id);
                        if (item[`${key}`] == null) {
                            reelMetrics[key].data.push(0);
                        } else {
                            reelMetrics[key].data.push(item[`${key}`]);
                        }
                    });
                });
            })
            .catch((error) => {
                console.error(error);
            });
    }, []);

    useEffect(() => {
        const url = baseUrl + "api/contract/signed";

        fetch(url, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + localStorage.getItem("access"),
            },
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error("Network response was not ok");
                }
                return response.json();
            })
            .then((data) => {
                setCampaigns(data);
            })
            .catch((error) => {
                console.error(error);
            });
    }, []);

    function handleRowClick (event) {
        navigate(`/campaigns/${event.data.id}`);
    }

    function newCampaignBtnHandler(event) {
        newCampaignOverlayRef.current.classList.toggle("not-visible");
    }

    function newCampaignCancelHandler(event) {
        newCampaignOverlayRef.current.classList.add("not-visible");
    }

    function newCampaignSaveHandler(event) {
        const url = baseUrl + "api/campaign/add"
        fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + localStorage.getItem("access"),
            },
            body: JSON.stringify({
                "campaign_name": newCampaignValue,
                "username": localStorage.getItem("username")
            })
        })
        .then((response) => {
            if (!response.ok) {
                throw new Error("Response not ok")
            }
            alert("Campaign Saved");
            newCampaignOverlayRef.current.classList.add("not-visible");
            return response.json()

        })
        .catch((error) => {
            alert("Error in saving campaign");
        })
    }

    useEffect(() => {
        const url = baseUrl + `api/campaign/get?username=${localStorage.getItem("username")}`;
        fetch(url, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + localStorage.getItem("access"),
            }
        })
        .then((response) => {
            if(!response.ok) {
                throw new Error("Grabbing campaigns failed");
            }
            return response.json();
        })
        .then((data) => {
            console.log("CAMPAIGNS GRABBED: ", data);
            setCampaignsTable([]);
            data.map((item) => {
                
                setCampaignsTable((prev) => {
                    return [...prev, {
                        id: item.id,
                        campaign_name: item.campaign_name,
                        // num_influencers: item.num_influencers,
                        // active_influencers: item.active_influencers,
                        // views: item.views,
                        // likes: item.likes,
                        // shares: item.shares,
                        // location: item.location,
                        date: item.date
                    }]
                })
            });
        })
        .catch((error) => {})
    }, []);
    
    return (
        <>
            {page == "campaigns" ? (
                <div className="campaign-management-container">
                    <div className="header">
                        <BreadCrumb model={items} home={home} />
                        <button onClick={newCampaignBtnHandler}>
                            <i className="pi pi-plus"></i>
                            <p>New Campaign</p>
                        </button>
                    </div>
                    <div className="new-campaign-overlay not-visible" ref={newCampaignOverlayRef}>
                        <div className="new-campaign-header">
                            <h2>Create New Campaign</h2>
                        </div>
                        <div className="new-campaign-content">
                            <label>Campaign Name</label>
                            <input type="text" placeholder="Campaign Name" value={newCampaignValue} onChange={(e) => {
                                setNewCampaignValue(e.target.value);
                            }} />
                        </div>
                        <div className="new-campaign-action">
                            <button onClick={newCampaignCancelHandler}>Cancel</button>
                            <button onClick={newCampaignSaveHandler}>Save</button>
                        </div>
                    </div>

                    <div className="campaigns">
                        <DataTable
                            value={campaignsTable}
                            paginator
                            showGridlines
                            rows={10}
                            loading={loading}
                            dataKey="id"
                            filters={filters}
                            globalFilterFields={['campaign_name', 'num_influencers', 'active_influencers', 'views', 'likes', 'shares', 'location']}
                            // header="Customer Table"
                            // header={header}
                            emptyMessage="No accounts found"
                            onFilter={(e) => setFilters(e.filters)}
                            onRowClick={handleRowClick}
                        >
                            <Column
                                field="campaign_name"
                                header="Campaign Name"
                                filter
                                filterPlaceholder="Search by Campaign"
                                style={{ minWidth: '7rem' }}
                            />
                            <Column
                                field="num_influencers"
                                header="Requested Influencers"
                                filter
                                filterPlaceholder="Search by Platform"
                                style={{ minWidth: '4rem' }}
                            />
                            <Column
                                field="active_influencers"
                                header="Active Influencers"
                                filter
                                style={{ minWidth: '4rem' }}
                            />
                            <Column
                                field="views"
                                header="Total Views"
                                filter
                                style={{ minWidth: '4rem' }}
                            />
                            <Column
                                field="likes"
                                header="Total Likes"
                                filter
                                style={{ minWidth: '4rem' }}
                            />
                            <Column
                                field="shares"
                                header="Total Shares"
                                filter
                                style={{ minWidth: '4rem' }}
                            />
                            {/* <Column
                                field="location"
                                header="Audience Location"
                                filter
                                style={{ minWidth: '4rem' }}
                            /> */}
                            <Column 
                                field="date"
                                header="Date"
                                stype={{ minWidth: '4rem' }}
                            />
                        </DataTable>
                        

                    </div>
                    <FileManager
                        fileDialogOpen={fileDialogOpen}
                        setFileDialogOpen={setFileDialogOpen}
                        selectedContractID={selectedContractID}
                        selectedVersionID={selectedVersionID}
                        selectedCampaignFile={selectedCampaignFile}
                        setSelectedCampaignFile={setSelectedCampaignFile}
                    />
                    
                </div>
            ) : null}
            {page == "analytics" ? (
                <div className="campaign-analytics-containers">
                    <div className="analytics">
                        <div className="analytics-engagement">
                            <div className="title">
                                <p>Engagement Metrics</p>
                                <span onClick={() => {
                                    setPage("campaigns");
                                }}>Campaigns</span>
                            </div>
                            <div className="sub-header">
                                <div className="navigation">
                                    <div
                                        className={
                                            engagementPage == "post-metrics"
                                                ? "nav selected"
                                                : "nav"
                                        }
                                        onClick={() => {
                                            setEngagementPage("post-metrics");
                                        }}
                                    >
                                        <p>Post Metrics</p>
                                    </div>
                                    <div
                                        className={
                                            engagementPage == "reel-metrics"
                                                ? "nav selected"
                                                : "nav"
                                        }
                                        onClick={() => {
                                            setEngagementPage("reel-metrics");
                                        }}
                                    >
                                        <p>Reel Metrics</p>
                                    </div>
                                </div>
                                <div className="filter">
                                    <DateRangePicker />
                                </div>
                                
                            </div>

                            {engagementPage === "post-metrics" ? (
                                <div className="content post-metrics">
                                    <div className="graph-container impressions">
                                        <div className="title">
                                            <p>Impressions</p>
                                        </div>
                                        <div className="graph">
                                            <LineGraph
                                                label={
                                                    postMetrics.impressions
                                                        .label
                                                }
                                                labels={
                                                    postMetrics.impressions
                                                        .labels
                                                }
                                                data={
                                                    postMetrics.impressions.data
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className="graph-container reach">
                                        <div className="title">
                                            <p>Reach</p>
                                        </div>
                                        <div className="graph">
                                            <LineGraph
                                                label={postMetrics.reach.label}
                                                labels={
                                                    postMetrics.reach.labels
                                                }
                                                data={postMetrics.reach.data}
                                            />
                                        </div>
                                    </div>
                                    <div className="graph-container likes">
                                        <div className="title">
                                            <p>Likes</p>
                                        </div>
                                        <div className="graph">
                                            <LineGraph
                                                label={
                                                    postMetrics.like_count.label
                                                }
                                                labels={
                                                    postMetrics.like_count
                                                        .labels
                                                }
                                                data={
                                                    postMetrics.like_count.data
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className="graph-container views">
                                        <div className="title">
                                            <p>Views</p>
                                        </div>
                                        <div className="graph">
                                            <LineGraph />
                                        </div>
                                    </div>
                                    <div className="graph-container shares">
                                        <div className="title">
                                            <p>Shares</p>
                                        </div>
                                        <div className="graph">
                                            <LineGraph
                                                label={postMetrics.shares.label}
                                                labels={
                                                    postMetrics.shares.labels
                                                }
                                                data={postMetrics.shares.data}
                                            />
                                        </div>
                                    </div>
                                    <div className="graph-container comments">
                                        <div className="title">
                                            <p>Comments</p>
                                        </div>
                                        <div className="graph">
                                            <LineGraph
                                                label={
                                                    postMetrics.comments_count
                                                        .label
                                                }
                                                labels={
                                                    postMetrics.comments_count
                                                        .labels
                                                }
                                                data={
                                                    postMetrics.comments_count
                                                        .data
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className="graph-container saves">
                                        <div className="title">
                                            <p>Saves</p>
                                        </div>
                                        <div className="graph">
                                            <LineGraph
                                                label={postMetrics.saved.label}
                                                labels={
                                                    postMetrics.saved.labels
                                                }
                                                data={postMetrics.saved.data}
                                            />
                                        </div>
                                    </div>
                                    <div className="graph-container video-views">
                                        <div className="title">
                                            <p>Video Views</p>
                                        </div>
                                        <div className="graph">
                                            <LineGraph
                                                label={
                                                    postMetrics.video_views
                                                        .label
                                                }
                                                labels={
                                                    postMetrics.video_views
                                                        .labels
                                                }
                                                data={
                                                    postMetrics.video_views.data
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className="graph-container sentiment">
                                        <div className="title">
                                            <p>Sentiment Analysis</p>
                                        </div>
                                        <div className="graph">
                                            <div className="content">
                                                <SentimentChart />
                                            </div>
                                            <div className="value">
                                                <p>75</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : null}

                            {engagementPage === "reel-metrics" ? (
                                <div className="content reel-metrics">
                                    <div className="graph-container reels-avg-watch-time">
                                        <div className="title">
                                            <p>Reels Avg. Watch Time</p>
                                        </div>
                                        <div className="graph">
                                            <LineGraph />
                                        </div>
                                    </div>
                                    <div className="graph-container plays">
                                        <div className="title">
                                            <p>Plays</p>
                                        </div>
                                        <div className="graph">
                                            <LineGraph />
                                        </div>
                                    </div>
                                    <div className="graph-container reels-view-total-time">
                                        <div className="title">
                                            <p>Reels View Total Time</p>
                                        </div>
                                        <div className="graph">
                                            <LineGraph />
                                        </div>
                                    </div>
                                    {/* <div className="graph-container reels-avg-watch-time">
                                        <div className="title">
                                            <p>Reels Avg. Watch Time</p>
                                        </div>
                                        <div className="graph">
                                            <LineGraph />
                                        </div>
                                    </div> */}
                                </div>
                            ) : null}
                        </div>
                        <div className="analytics-demographics">
                            <div className="title">Audience Demographics</div>
                            <div className="sub-header">
                                <div className="navigation">
                                    <div
                                        className={
                                            demographicsPage == "country"
                                                ? "nav selected"
                                                : "nav"
                                        }
                                        onClick={() => {
                                            setDemographicsPage("country");
                                        }}
                                    >
                                        <p>Country</p>
                                    </div>
                                    <div
                                        className={
                                            demographicsPage == "city"
                                                ? "nav selected"
                                                : "nav"
                                        }
                                        onClick={() => {
                                            setDemographicsPage("city");
                                        }}
                                    >
                                        <p>City</p>
                                    </div>
                                    <div
                                        className={
                                            demographicsPage == "age"
                                                ? "nav selected"
                                                : "nav"
                                        }
                                        onClick={() => {
                                            setDemographicsPage("age");
                                        }}
                                    >
                                        <p>Age</p>
                                    </div>
                                    <div
                                        className={
                                            demographicsPage == "gender"
                                                ? "nav selected"
                                                : "nav"
                                        }
                                        onClick={() => {
                                            setDemographicsPage("gender");
                                        }}
                                    >
                                        <p>Gender</p>
                                    </div>
                                </div>
                                <div className="filter">
                                    <DateRangePicker />
                                </div>
                            </div>
                            {demographicsPage === "country" ? (
                                <div className="content">
                                    <div className="follower-demographics">
                                        <div className="title">
                                            <p>Follower Demographics</p>
                                        </div>
                                        <div className="content">
                                            <div className="row">
                                                <div className="column">
                                                    <p>Country</p>
                                                </div>
                                                <div className="column">
                                                    <p>#</p>
                                                </div>
                                            </div>
                                            {countryDemographics.follower_demographics.map(
                                                (item) => {
                                                    return (
                                                        <div className="row">
                                                            <div className="column">
                                                                <p>
                                                                    {
                                                                        item.this_week_country
                                                                    }
                                                                </p>
                                                            </div>
                                                            <div className="column">
                                                                <p>
                                                                    {
                                                                        item.this_week_follower_count
                                                                    }
                                                                </p>
                                                            </div>
                                                        </div>
                                                    );
                                                }
                                            )}
                                        </div>
                                    </div>
                                    <div className="engaged-demographics">
                                        <div className="title">
                                            <p>Engaged Demographics</p>
                                        </div>
                                        <div className="content">
                                            <div className="row">
                                                <div className="column">
                                                    <p>Country</p>
                                                </div>
                                                <div className="column">
                                                    <p>#</p>
                                                </div>
                                            </div>
                                            {countryDemographics.engaged_audience_demographics.map(
                                                (item) => {
                                                    return (
                                                        <div className="row">
                                                            <div className="column">
                                                                <p>
                                                                    {
                                                                        item.this_week_country
                                                                    }
                                                                </p>
                                                            </div>
                                                            <div className="column">
                                                                <p>
                                                                    {
                                                                        item.this_week_follower_count
                                                                    }
                                                                </p>
                                                            </div>
                                                        </div>
                                                    );
                                                }
                                            )}
                                        </div>
                                    </div>
                                    <div className="reached-demographics">
                                        <div className="title">
                                            <p>Reached Demographics</p>
                                        </div>
                                        <div className="content">
                                            <div className="row">
                                                <div className="column">
                                                    <p>Country</p>
                                                </div>
                                                <div className="column">
                                                    <p>#</p>
                                                </div>
                                            </div>
                                            {countryDemographics.reached_audience_demographics.map(
                                                (item) => {
                                                    return (
                                                        <div className="row">
                                                            <div className="column">
                                                                <p>
                                                                    {
                                                                        item.this_week_country
                                                                    }
                                                                </p>
                                                            </div>
                                                            <div className="column">
                                                                <p>
                                                                    {
                                                                        item.this_week_follower_count
                                                                    }
                                                                </p>
                                                            </div>
                                                        </div>
                                                    );
                                                }
                                            )}
                                        </div>
                                    </div>
                                </div>
                            ) : null}
                            {demographicsPage === "city" ? (
                                <div className="content">
                                    <div className="follower-demographics">
                                        <div className="title">
                                            <p>Follower Demographics</p>
                                        </div>
                                        <div className="content">
                                            <div className="row">
                                                <div className="column">
                                                    <p>City</p>
                                                </div>
                                                <div className="column">
                                                    <p>#</p>
                                                </div>
                                            </div>
                                            {cityDemographics.follower_demographics.map(
                                                (item) => {
                                                    return (
                                                        <div className="row">
                                                            <div className="column">
                                                                <p>
                                                                    {item.this_week_city
                                                                        .split(
                                                                            ","
                                                                        )[0]
                                                                        .substring(
                                                                            2
                                                                        )}
                                                                </p>
                                                            </div>
                                                            <div className="column">
                                                                <p>
                                                                    {
                                                                        item.this_week_follower_count
                                                                    }
                                                                </p>
                                                            </div>
                                                        </div>
                                                    );
                                                }
                                            )}
                                        </div>
                                    </div>
                                    <div className="engaged-demographics">
                                        <div className="title">
                                            <p>Engaged Demographics</p>
                                        </div>
                                        <div className="content">
                                            <div className="row">
                                                <div className="column">
                                                    <p>City</p>
                                                </div>
                                                <div className="column">
                                                    <p>#</p>
                                                </div>
                                            </div>
                                            {cityDemographics.engaged_audience_demographics.map(
                                                (item) => {
                                                    return (
                                                        <div className="row">
                                                            <div className="column">
                                                                <p>
                                                                    {item.this_week_city
                                                                        .split(
                                                                            ","
                                                                        )[0]
                                                                        .substring(
                                                                            2
                                                                        )}
                                                                </p>
                                                            </div>
                                                            <div className="column">
                                                                <p>
                                                                    {
                                                                        item.this_week_follower_count
                                                                    }
                                                                </p>
                                                            </div>
                                                        </div>
                                                    );
                                                }
                                            )}
                                        </div>
                                    </div>
                                    <div className="reached-demographics">
                                        <div className="title">
                                            <p>Reached Demographics</p>
                                        </div>
                                        <div className="content">
                                            <div className="row">
                                                <div className="column">
                                                    <p>City</p>
                                                </div>
                                                <div className="column">
                                                    <p>#</p>
                                                </div>
                                            </div>
                                            {cityDemographics.reached_audience_demographics.map(
                                                (item) => {
                                                    return (
                                                        <div className="row">
                                                            <div className="column">
                                                                <p>
                                                                    {item.this_week_city
                                                                        .split(
                                                                            ","
                                                                        )[0]
                                                                        .substring(
                                                                            2
                                                                        )}
                                                                </p>
                                                            </div>
                                                            <div className="column">
                                                                <p>
                                                                    {
                                                                        item.this_week_follower_count
                                                                    }
                                                                </p>
                                                            </div>
                                                        </div>
                                                    );
                                                }
                                            )}
                                        </div>
                                    </div>
                                </div>
                            ) : null}
                            {demographicsPage === "age" ? (
                                <div className="content age">
                                    <div className="follower-demographics">
                                        <div className="title">
                                            <p>Follower Demographics</p>
                                        </div>
                                        <div className="content">
                                            <AgeGroupChart
                                                data={
                                                    ageDemographics.follower_demographics
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className="engaged-demographics">
                                        <div className="title">
                                            <p>Engaged Demographics</p>
                                        </div>
                                        <div className="content">
                                            <AgeGroupChart
                                                data={
                                                    ageDemographics.engaged_audience_demographics
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className="reached-demographics">
                                        <div className="title">
                                            <p>Reached Demographics</p>
                                        </div>
                                        <div className="content">
                                            <AgeGroupChart
                                                data={
                                                    ageDemographics.reached_audience_demographics
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                            ) : null}
                            {demographicsPage === "gender" ? (
                                <div className="content">
                                    <div className="follower-demographics">
                                        <div className="title">
                                            <p>Follower Demographics</p>
                                        </div>
                                        <div className="content">
                                            <GenderChart
                                                data={
                                                    genderDemographics.follower_demographics
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className="engaged-demographics">
                                        <div className="title">
                                            <p>Engaged Demographics</p>
                                        </div>
                                        <div className="content">
                                            <GenderChart
                                                data={
                                                    genderDemographics.engaged_audience_demographics
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className="reached-demographics">
                                        <div className="title">
                                            <p>Reached Demographics</p>
                                        </div>
                                        <div className="content">
                                            <GenderChart
                                                data={
                                                    genderDemographics.reached_audience_demographics
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                            ) : null}
                        </div>
                    </div>
                </div>
            ) : null}
        </>
    );
}
