import { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { LoginContext } from "../App";
import "./Discovery.css";
import MoreFilters from "../components/MoreFilters";
import ChatSearch from "../components/ChatSearch";
import { baseUrl } from "../shared";
import ReactFlagsSelect from "react-flags-select";
import NicheSelect from "../components/NicheSelect";
// import {  Table,  TableHeader,  TableBody,  TableColumn,  TableRow,  TableCell} from "@nextui-org/table";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { FilterMatchMode } from 'primereact/api';



export default function Discovery() {
    const [loggedIn, setLoggedIn] = useContext(LoginContext);
    const navigate = useNavigate();
    const [filterValues, setFilterValues] = useState({
        username: "",
        name: "",
        followers: 0,
        price: 0,
        location: "",
        niche: "",
    });
    const [selected, setSelected] = useState("");
    const [selectedCountryName, setSelectedCountryName] = useState("");
    const [selectedCountryFlag, setSelectedCountryFlag] = useState("");
    const [selectedCountry, setSelectedCountry] = useState([]);
    const [filterResponse, setFilterResponse] = useState([]);
    const [selectedNiches, setSelectedNiches] = useState([]);

    // const customers = [
    //     { id: 1, username: "James Butt", platform: "Instagram", followers: 12000, engagement: "4.5%", views: 1500, likes: 340, location: "Algeria" },
    //     { id: 2, username: "Josephine Darakjy", platform: "YouTube", followers: 54000, engagement: "8.2%", views: 8400, likes: 1200, location: "Egypt" },
    //     { id: 3, username: "Art Venere", platform: "TikTok", followers: 30000, engagement: "7.0%", views: 3200, likes: 800, location: "Panama" },
    //     { id: 4, username: "Lenna Paprocki", platform: "Instagram", followers: 45000, engagement: "5.1%", views: 5000, likes: 1500, location: "Slovenia" },
    //     { id: 5, username: "Donette Foller", platform: "Facebook", followers: 20000, engagement: "3.3%", views: 2800, likes: 400, location: "South Africa" },
    //     { id: 6, username: "Simona Morasca", platform: "YouTube", followers: 34000, engagement: "6.4%", views: 6700, likes: 900, location: "Egypt" },
    //     { id: 7, username: "Mitsue Tollner", platform: "TikTok", followers: 15000, engagement: "5.0%", views: 1900, likes: 600, location: "Paraguay" },
    //     { id: 8, username: "Leota Dilliard", platform: "Instagram", followers: 60000, engagement: "10.2%", views: 10200, likes: 2100, location: "Serbia" }
    // ];
    
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        username: { operator: 'and', constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
        platform: { operator: 'and', constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
        followers: { operator: 'and', constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
    });
    
    const [loading, setLoading] = useState(false); // Loading state
    

    const renderHeader = () => {
        return (
            <div className="table-header">
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText
                        type="search"
                        onInput={(e) =>
                            setFilters({ ...filters, global: { value: e.target.value, matchMode: FilterMatchMode.CONTAINS } })
                        }
                        placeholder="Keyword Search"
                    />
                </span>
            </div>
        );
    };
    
    const header = renderHeader();
    

    useEffect(() => {
        console.log("selected niches", selectedNiches);
    }, [selectedNiches]);

    useEffect(() => {
        if (!loggedIn) {
            navigate("/login");
        }
    }, []);

    // function filterResults() {
    //     const url =
    //         baseUrl +
    //         `api/instagram/filter?username=${filterValues.username}&name=${filterValues.name}&followers=${filterValues.followers}&price=${filterValues.price}&location=${filterValues.location}`;
    //     fetch(url, {
    //         method: "GET",
    //         headers: {
    //             "Content-Type": "application/json",
    //             "Authorization": "Bearer " + localStorage.getItem("access"),
    //         },
    //     })
    //         .then((response) => {
    //             if (!response.ok) {
    //                 throw new Error("Error fetching data");
    //             }
    //             return response.json();
    //         })
    //         .then((data) => {
    //             console.log(data);
    //         })
    //         .catch((error) => {
    //             console.log(error);
    //         });
    // }

    // Filter Youtube Results
    // function filterResults() {
    //     const url =
    //         baseUrl +
    //         `api/youtube/filter?username=${filterValues.username}&name=${filterValues.name}&followers=${filterValues.followers}&price=${filterValues.price}&location=${filterValues.location}`;
    //     fetch(url, {
    //         method: "GET",
    //         headers: {
    //             "Content-Type": "application/json",
    //             "Authorization": "Bearer " + localStorage.getItem("access"),
    //         }
    //     })
    //     .then((response) => {
    //         if (!response.ok) {
    //             throw new Error("Error fetching data");
    //         }
    //         return response.json();
    //     })
    //     .then((data) => {
    //         console.log("YOUTUBE DATA", data);
    //     })
    //     .catch((error) => {console.log(error)});
    // }

    function updateSelectedCountry(code) {
        setSelected(code);
        const countryContainer = document.querySelectorAll(
            ".menu-flags-select button span span"
        );

        setTimeout(() => {
            const countryContainer = document.querySelectorAll(
                ".menu-flags-select button span span"
            );
            const countryName = countryContainer[1]?.textContent;
            const countryFlag = countryContainer[0]?.innerHTML;

            const newCountry = { code, name: countryName, flag: countryFlag };

            setSelectedCountry((prevSelected) => {
                // Check if the country already exists in the array
                if (!prevSelected.some((country) => country.code === code)) {
                    // If it doesn't exist, add it
                    return [...prevSelected, newCountry];
                }
                // If it exists, return the array unchanged
                return prevSelected;
            });
        }, 0);
    }

    // useEffect(() => {
    //     console.log("SELECTED COUNTRY", selectedCountry);

    //     const countryCodes = selectedCountry.map((country) => country.code);
    //     console.log("COUNTRY CODES", countryCodes);
    //     const url = new URL(baseUrl + `api/instagram/filter`);
    //     const params = {
    //         username: filterValues.username,
    //         name: filterValues.name,
    //         followers: filterValues.followers,
    //         price: filterValues.price,
    //         location: countryCodes,
    //     };

    //     Object.keys(params).forEach((key) =>
    //         url.searchParams.append(key, params[key])
    //     );

    //     console.log("URL", url);

    //     fetch(url, {
    //         method: "GET",
    //         headers: {
    //             "Content-Type": "application/json",
    //             Authorization: "Bearer " + localStorage.getItem("access"),
    //         },
    //     })
    //         .then((response) => {
    //             if (!response.ok) {
    //                 throw new Error("Error fetching data");
    //             }
    //             return response.json();
    //         })
    //         .then((data) => {
    //             console.log(data);
    //             setFilterResponse(data);
    //         })
    //         .catch((error) => {
    //             console.log(error);
    //         });
    // }, [selectedCountry, filterValues]);

    //Youtube 

    // useEffect(() => {
    //     const countryCodes = selectedCountry.map((country) => country.code);
    //     const url = new URL(baseUrl + `api/youtube/filter`);
    //     const params = {
    //         username: filterValues.username,
    //         name: filterValues.name,
    //         followers: filterValues.followers,
    //         price: filterValues.price,
    //         location: countryCodes,
    //     };

    //     Object.keys(params).forEach((key) =>
    //         url.searchParams.append(key, params[key])
    //     );

    //     console.log("URL", url);

    //     fetch(url, {
    //         method: "GET",
    //         headers: {
    //             "Content-Type": "application/json",
    //             Authorization: "Bearer " + localStorage.getItem("access"),
    //         },
    //     })
    //         .then((response) => {
    //             if (!response.ok) {
    //                 throw new Error("Error fetching data");
    //             }
    //             return response.json();
    //         })
    //         .then((data) => {
    //             console.log(data);
    //             setFilterResponse(data);
    //         })
    //         .catch((error) => {
    //             console.log(error);
    //         });
    // }, [selectedCountry, filterValues]);

    useEffect(() => {
        const countryCodes = selectedCountry.map((country) => country.code);
        const url = new URL(baseUrl + 'api/phyllo/account/filter');
        const params = {
            phyllo_account_platform_username: filterValues.username,
            phyllo_user_name: filterValues.name,
            phyllo_reputation_follower_count_min: filterValues.followers,
            // price: filterValues.price,
            // location: countryCodes,
            country: countryCodes, 
            // city: countryCodes,
        };

        Object.keys(params).forEach((key) =>
            url.searchParams.append(key, params[key])
        );

        console.log("URL", url);

        fetch(url, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + localStorage.getItem("access"),
            },
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error("Error fetching data");
                }
                return response.json();
            })
            .then((data) => {
                console.log("Filtered data", data);
                setFilterResponse([]);
                setFilterResponse(data.map((item) => ({
                    id: item.id,
                    username: item.phyllo_account_platform_username,
                    platform: item.phyllo_work_platform_name,
                    followers: item.phyllo_reputation_follower_count,
                    engagement: item.engagement_rate, 
                    views: item.avg_view_count, 
                    likes: item.avg_like_count,
                    location: item.phyllo_country,
                    account_id: item.phyllo_accountid,
                })));
            })
            .catch((error) => {
                console.log(error);
            });
    }, [selectedCountry, filterValues]);

    const customers = [
        { id: 1, username: "James Butt", platform: "Instagram", followers: 12000, engagement: "4.5%", views: 1500, likes: 340, location: "Algeria" },
        { id: 2, username: "Josephine Darakjy", platform: "YouTube", followers: 54000, engagement: "8.2%", views: 8400, likes: 1200, location: "Egypt" },
        { id: 3, username: "Art Venere", platform: "TikTok", followers: 30000, engagement: "7.0%", views: 3200, likes: 800, location: "Panama" },
        { id: 4, username: "Lenna Paprocki", platform: "Instagram", followers: 45000, engagement: "5.1%", views: 5000, likes: 1500, location: "Slovenia" },
        { id: 5, username: "Donette Foller", platform: "Facebook", followers: 20000, engagement: "3.3%", views: 2800, likes: 400, location: "South Africa" },
        { id: 6, username: "Simona Morasca", platform: "YouTube", followers: 34000, engagement: "6.4%", views: 6700, likes: 900, location: "Egypt" },
        { id: 7, username: "Mitsue Tollner", platform: "TikTok", followers: 15000, engagement: "5.0%", views: 1900, likes: 600, location: "Paraguay" },
        { id: 8, username: "Leota Dilliard", platform: "Instagram", followers: 60000, engagement: "10.2%", views: 10200, likes: 2100, location: "Serbia" }
    ];

    // function filterResults() {
    //     const countryCodes = selectedCountry.map((country) => country.code);
    //     const url =
    //         baseUrl +
    //         `api/phyllo/account/filter?phyllo_account_platform_username=${filterValues.username}&phyllo_user_name=${filterValues.name}&phyllo_reputation_follower_count_min=${filterValues.followers}&country=${countryCodes}`; //price=${filterValues.price}&location=${filterValues.location}`;
    //     fetch(url, {
    //         method: "GET",
    //         headers: {
    //             "Content-Type": "application/json",
    //             "Authorization": "Bearer " + localStorage.getItem("access"),
    //         }
    //     })
    //     .then((response) => {
    //         if (!response.ok) {
    //             throw new Error("Error fetching data");
    //         }
    //         return response.json();
    //     })
    //     .then((data) => {
    //         console.log("Phyllo Data", data);
    //     })
    //     .catch((error) => {console.log(error)});
    // }

    function influencerTableRowClickHandler(event) {
        navigate(`/profile/${event.data.platform}/${event.data.account_id}`);
    }
    

    return (
        <div className="main-container">
            <div className="sidebar">
                <div className="title">
                    <p>Filters</p>
                </div>
                <div className="filters">
                    <div className="filter username">
                        <div className="filter-title">
                            <span class="material-symbols-outlined">
                                person
                            </span>
                            <p>Username</p>
                        </div>
                        <div className="filter-content">
                            <input
                                type="text"
                                value={filterValues.username}
                                onChange={(e) => {
                                    setFilterValues({
                                        ...filterValues,
                                        username: e.target.value,
                                    });
                                    // filterResults();
                                }}
                            />
                        </div>
                    </div>
                    <div className="filter name">
                        <div className="filter-title">
                            <span class="material-symbols-outlined">badge</span>
                            <p>Name</p>
                        </div>
                        <div className="filter-content">
                            <input
                                type="text"
                                value={filterValues.name}
                                onChange={(e) => {
                                    setFilterValues({
                                        ...filterValues,
                                        name: e.target.value,
                                    });
                                    // filterResults();
                                }}
                            />
                        </div>
                    </div>
                    <div className="filter followers">
                        <div className="filter-title">
                            <span class="material-symbols-outlined">
                                people
                            </span>
                            <p>Followers</p>
                        </div>
                        <div className="filter-content">
                            <input
                                type="range"
                                min="0"
                                max="100000"
                                value={filterValues.followers}
                                onChange={(e) => {
                                    setFilterValues({
                                        ...filterValues,
                                        followers: e.target.value,
                                    });
                                    // filterResults();
                                }}
                            />
                            <p>{filterValues.followers}</p>
                        </div>
                    </div>
                    <div className="filter followers">
                        <div className="filter-title">
                            <span class="material-symbols-outlined">
                                people
                            </span>
                            <p>Price</p>
                        </div>
                        <div className="filter-content">
                            <input
                                type="range"
                                min="0"
                                max="100000"
                                value={filterValues.price}
                                onChange={(e) => {
                                    setFilterValues({
                                        ...filterValues,
                                        price: e.target.value,
                                    });
                                    // filterResults();
                                }}
                            />
                            <p>{filterValues.price}</p>
                        </div>
                    </div>
                    <div className="filter location">
                        <div className="filter-title">
                            <span class="material-symbols-outlined">
                                location_on
                            </span>
                            <p>Audience Location</p>
                        </div>
                        <div className="filter-content">
                            {/* <input type="text" /> */}
                            {/* <LocationSuggest /> */}
                            <ReactFlagsSelect
                                selected={selected}
                                onSelect={(code) => updateSelectedCountry(code)}
                                searchable
                                searchPlaceholder="Search countries"
                                className="menu-flags-select"
                                showSelectedLabel={true}
                            />
                            <div className="selected-locations">
                                <span>Selected Locations</span>
                                <div className="container">
                                    {selectedCountry.map((country, index) => {
                                        return (
                                            <>
                                                <div className="location">
                                                    <div
                                                        className="flag"
                                                        dangerouslySetInnerHTML={{
                                                            __html: country.flag,
                                                        }}
                                                    />
                                                    <div className="name">
                                                        {country.code}
                                                    </div>
                                                    <div className="delete">
                                                        <span
                                                            class="material-symbols-outlined"
                                                            onClick={() => {
                                                                setSelectedCountry(
                                                                    selectedCountry.filter(
                                                                        (
                                                                            item,
                                                                            i
                                                                        ) =>
                                                                            i !==
                                                                            index
                                                                    )
                                                                );
                                                            }}
                                                        >
                                                            close
                                                        </span>
                                                    </div>
                                                </div>
                                            </>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="filter niches">
                        <div className="filter-title">
                            <span class="material-symbols-outlined">
                                category
                            </span>
                            <p>Niche</p>
                        </div>
                        <div className="filter-content">
                            {/* <input
                                type="text"
                                value={filterValues.niche}
                                onChange={(e) => {
                                    setFilterValues({
                                        ...filterValues,
                                        niche: e.target.value,
                                    });
                                    filterResults();
                                }}
                            /> */}
                            <NicheSelect
                                selectedNiches={selectedNiches}
                                setSelectedNiches={setSelectedNiches}
                            />
                            <div className="selected-niches">
                                <span>Selected Niches</span>
                                <div className="container">
                                    {selectedNiches.map((niche) => {
                                        return (
                                            <>
                                                <div className="niche-item">
                                                    <div className="name">
                                                        {niche}
                                                    </div>
                                                    <div className="delete">
                                                        <span
                                                            class="material-symbols-outlined"
                                                            onClick={() => {
                                                                setSelectedNiches(
                                                                    selectedNiches.filter(
                                                                        (
                                                                            item
                                                                        ) =>
                                                                            item !==
                                                                            niche
                                                                    )
                                                                );
                                                            }}
                                                        >
                                                            close
                                                        </span>
                                                    </div>
                                                </div>
                                            </>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="discovery-container">
                <div className="chat-search-container">
                    <ChatSearch />
                    {/* <div className="title">
                        <p>Chat Search</p>
                    </div>
                    <div className="chat-search">
                        <input
                            type="text"
                            placeholder="Describe your ideal influencer"
                        />
                    </div> */}
                </div>
                <div className="results-table">
                    <DataTable
                        value={filterResponse}
                        paginator
                        showGridlines
                        rows={10}
                        loading={loading}
                        dataKey="id"
                        filters={filters}
                        globalFilterFields={['username', 'platform', 'followers', 'engagement', 'views', 'likes', 'location']}
                        // header="Customer Table"
                        // header={header}
                        emptyMessage="No accounts found"
                        onFilter={(e) => setFilters(e.filters)}
                        onRowClick={influencerTableRowClickHandler}
                    >
                        <Column
                            field="username"
                            header="Username"
                            filter
                            filterPlaceholder="Search by Username"
                            style={{ minWidth: '7rem' }}
                        />
                        <Column
                            field="platform"
                            header="Platform"
                            filter
                            filterPlaceholder="Search by Platform"
                            style={{ minWidth: '4rem' }}
                        />
                        <Column
                            field="followers"
                            header="Followers"
                            filter
                            style={{ minWidth: '4rem' }}
                        />
                        <Column
                            field="engagement"
                            header="Engagement"
                            filter
                            style={{ minWidth: '4rem' }}
                        />
                        <Column
                            field="views"
                            header="Views"
                            filter
                            style={{ minWidth: '4rem' }}
                        />
                        <Column
                            field="likes"
                            header="Likes"
                            filter
                            style={{ minWidth: '4rem' }}
                        />
                        <Column
                            field="location"
                            header="Audience Location"
                            filter
                            style={{ minWidth: '4rem' }}
                        />
                    </DataTable>
                    
                </div>
            </div>
        </div>
    );
}
